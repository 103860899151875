<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="'Excel' га чиқариш"
    placement="top-start"
  >
    <el-button
      class="t-btn"
      style="
        min-width: 5rem;
        height: 2.5rem;
        background-color: #01a39b;
        color: white;
      "
      :loading="isLoading"
      @click="$emit('excel-download-emit', 22)"
    >
      <!-- <svg-icon style="fill: white" icon-class="excel" /> -->
      {{ isLoading ? 'Yuklanyapti...' : 'Excel' }}
    </el-button>
  </el-tooltip>
</template>
<script>
// import { parseTime } from '@/utils'
// import { mapGetters } from 'vuex'
// import axios from 'axios'
export default {
  name: 'DHJExcel',
  // created() {},
  computed: {
    // ...mapGetters(['filteredReport4']),

    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  methods: {
    // handleDownload() {
    //   this.$store.commit('setIsLoading', true)
    //   axios({
    //     method: 'get',
    //     url: `reports/report21/excel/${this.$route.query.corp_card_id}/`,
    //     responseType: 'blob'
    //   }).then(response => {
    //     const url = URL.createObjectURL(
    //       new Blob([response.data], {
    //         type: 'application/vnd.ms-excel'
    //       })
    //     )
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.setAttribute('download', 'Report7')
    //     document.body.appendChild(link)
    //     link.click()
    //     link.outerHTML = null
    //     this.$store.commit('setIsLoading', false)
    //   })
    // }
    // formatJson(filterVal, jsonData) {
    //   return jsonData.map(v =>
    //     filterVal.map(j => {
    //       if (j === 'timestamp') {
    //         return parseTime(v[j])
    //       } else {
    //         return v[j]
    //       }
    //     })
    //   )
    // }
  }
}
</script>
